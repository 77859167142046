// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

 @media (max-width : 599px) {
    .selectsubplan{
        color: #1976D2 !important;
        font-weight: bold;

        & .MuiOutlinedInput-notchedOutline{border-color: #1976D2 !important;} 
          
    
        &.Mui-focused .MuiOutlinedInput-notchedOutline{
        
            border-color: #1976D2 !important;
          };
    }
    .arrowDownIcon{
      color: #1976D2 !important;
    }
 }
`, "",{"version":3,"sources":["webpack://./src/components/SubPlanSelect.css"],"names":[],"mappings":";;CAEC;IACG;QACI,yBAAyB;QACzB,iBAAiB;;QAEjB,mCAAmC,gCAAgC,CAAC;;;QAGpE;;YAEI,gCAAgC;UAClC,CAAA;IACN;IACA;MACE,yBAAyB;IAC3B;CACH","sourcesContent":["\n\n @media (max-width : 599px) {\n    .selectsubplan{\n        color: #1976D2 !important;\n        font-weight: bold;\n\n        & .MuiOutlinedInput-notchedOutline{border-color: #1976D2 !important;} \n          \n    \n        &.Mui-focused .MuiOutlinedInput-notchedOutline{\n        \n            border-color: #1976D2 !important;\n          };\n    }\n    .arrowDownIcon{\n      color: #1976D2 !important;\n    }\n }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import React from 'react'
import BookingCard from '../components/BookingCard'
import {Grid} from '@mui/material'


export default function RoutedPlanPage({plan}) {
  return (
    <>
     <Grid container className='HomePageContainer'>
        { plan.status === 'closed'?
           <Grid item xs={12} md={4}> 
              <div>This Plan is Not Avalaible</div>
           </Grid>
           :
        <Grid item xs={12} md={4} >
           <BookingCard Plan={plan}/>
        </Grid>
        }
    </Grid> 
    </>
  )
}

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ToggleBarContainer {
  background: #f5f5f5;
  border-bottom: 1px solid #ddd;
  padding: 12px 0;
  overflow-x: auto; 
  white-space: nowrap; 
  display: flex;
  justify-content: center; 
  flex-shrink: 0; 
}

.ToggleBar {
  display: flex;
  align-items: center;
  flex-wrap: nowrap; 
}

.ToggleBar .ToggleButton{
  margin: 8px 12px;
}

.ToggleButton {
  min-width: 150px;
  margin: 0 16px; /* Increased horizontal margin for more spacing between buttons */
  text-transform: none;
  border-radius: 20px;
  color: #333;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  flex: 0 0 auto;
  padding: 8px 16px;
  font-size: 0.875rem;
  font-weight: 500;
  transition: background-color 0.3s, box-shadow 0.3s, color 0.3s;
}

.ToggleButton:hover {
  background-color: #ddd;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.ToggleButton.selected {
  background-color: #007fff;
  color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

@media (max-width: 600px) {
  .ToggleBarContainer {
    padding: 12px 8px; 
    overflow-x: auto; 
    justify-content: flex-start; 
  }

  .ToggleButton {
    min-width: 120px; 
    font-size: 0.75rem; 
    margin: 0 12px; 
  }
}
`, "",{"version":3,"sources":["webpack://./src/layouts/ToggleBar.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,6BAA6B;EAC7B,eAAe;EACf,gBAAgB;EAChB,mBAAmB;EACnB,aAAa;EACb,uBAAuB;EACvB,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,iBAAiB;AACnB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;EAChB,cAAc,EAAE,iEAAiE;EACjF,oBAAoB;EACpB,mBAAmB;EACnB,WAAW;EACX,sBAAsB;EACtB,wCAAwC;EACxC,cAAc;EACd,iBAAiB;EACjB,mBAAmB;EACnB,gBAAgB;EAChB,8DAA8D;AAChE;;AAEA;EACE,sBAAsB;EACtB,yCAAyC;AAC3C;;AAEA;EACE,yBAAyB;EACzB,WAAW;EACX,wCAAwC;AAC1C;;AAEA;EACE;IACE,iBAAiB;IACjB,gBAAgB;IAChB,2BAA2B;EAC7B;;EAEA;IACE,gBAAgB;IAChB,kBAAkB;IAClB,cAAc;EAChB;AACF","sourcesContent":[".ToggleBarContainer {\n  background: #f5f5f5;\n  border-bottom: 1px solid #ddd;\n  padding: 12px 0;\n  overflow-x: auto; \n  white-space: nowrap; \n  display: flex;\n  justify-content: center; \n  flex-shrink: 0; \n}\n\n.ToggleBar {\n  display: flex;\n  align-items: center;\n  flex-wrap: nowrap; \n}\n\n.ToggleBar .ToggleButton{\n  margin: 8px 12px;\n}\n\n.ToggleButton {\n  min-width: 150px;\n  margin: 0 16px; /* Increased horizontal margin for more spacing between buttons */\n  text-transform: none;\n  border-radius: 20px;\n  color: #333;\n  background-color: #fff;\n  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);\n  flex: 0 0 auto;\n  padding: 8px 16px;\n  font-size: 0.875rem;\n  font-weight: 500;\n  transition: background-color 0.3s, box-shadow 0.3s, color 0.3s;\n}\n\n.ToggleButton:hover {\n  background-color: #ddd;\n  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);\n}\n\n.ToggleButton.selected {\n  background-color: #007fff;\n  color: #fff;\n  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);\n}\n\n@media (max-width: 600px) {\n  .ToggleBarContainer {\n    padding: 12px 8px; \n    overflow-x: auto; \n    justify-content: flex-start; \n  }\n\n  .ToggleButton {\n    min-width: 120px; \n    font-size: 0.75rem; \n    margin: 0 12px; \n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bookingclosedBox{
    background: #FF4C4C;
    color: #ffff;
    width: 90%;
    height: 100%;
    border-radius: 0.4rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.closed-btn{
    background: #FF4C4C !important;
}`, "",{"version":3,"sources":["webpack://./src/components/Checkout.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,YAAY;IACZ,UAAU;IACV,YAAY;IACZ,qBAAqB;IACrB,aAAa;IACb,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,8BAA8B;AAClC","sourcesContent":[".bookingclosedBox{\n    background: #FF4C4C;\n    color: #ffff;\n    width: 90%;\n    height: 100%;\n    border-radius: 0.4rem;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n}\n\n.closed-btn{\n    background: #FF4C4C !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

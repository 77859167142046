// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.HomePageContainer{
    padding: 1rem;
}
@media (max-width: 600px) {
    .HomePageContainer {
        padding: 0.4rem;
    }
}
`, "",{"version":3,"sources":["webpack://./src/pages/HomePage.css"],"names":[],"mappings":"AAAA;IACI,aAAa;AACjB;AACA;IACI;QACI,eAAe;IACnB;AACJ","sourcesContent":[".HomePageContainer{\n    padding: 1rem;\n}\n@media (max-width: 600px) {\n    .HomePageContainer {\n        padding: 0.4rem;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import React, { useContext, useState } from "react";
import { Box, Typography, Button } from "@mui/material";
import { PlanContext } from "../contexts/PlanContext";
import "./ToggleBar.css";
import { useNavigate } from "react-router-dom";

const ToggleBar = () => {
  const { plans } = useContext(PlanContext);
  const [selectedPlan, setSelectedPlan] = useState(plans[0]?._id);
  const navigate = useNavigate();

  if (!Array.isArray(plans)) {
    return <Box className="ToggleBarContainer">Plans data is not an array</Box>;
  }

  if (plans.length === 0) {
    return <Box className="ToggleBarContainer">No plans available</Box>;
  }

  const handlePlanClick = (planId) => {
    setSelectedPlan(planId);
     navigate(`knowmore/${planId}`);
  };

  return (
    <Box className="ToggleBarContainer">
      <Box className="ToggleBar">
        {plans.map((plan) => (
          <Button
            key={plan._id}
            className={`ToggleButton ${
              selectedPlan === plan._id ? "selected" : ""
            }`}
            onClick={() => handlePlanClick(plan._id)}
          >
            <Typography variant="body2">{plan.title}</Typography>
          </Button>
        ))}
      </Box>
    </Box>
  );
};

export default ToggleBar;

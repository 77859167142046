import * as React from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import './SubPlanSelect.css';
export default function SubPlanSelect({
  Subpackages,
  selectedSubPackage,
  setSelectedSubPackage,
})
 {
    React.useEffect(()=>{ setSelectedSubPackage(Subpackages[0])},[Subpackages]);

  const handleChange = (event) => {
    const subpackageName = event.target.value;
    const selectedPackage = Subpackages.find((subpackage)=>subpackage.name === subpackageName);
    setSelectedSubPackage(selectedPackage || null);
  };

  return (
    <FormControl sx={{ my: 1, width: "100%" }} size="small">
      <InputLabel id="demo-select-small-label" sx={{ color: "#ffff" }} className="arrowDownIcon">
        Sub Plan
      </InputLabel>
      <Select
        labelId="demo-select-small-label"
        id="demo-select-small"
        value={selectedSubPackage?.name || ""}
       className="selectsubplan"
        IconComponent={(props) => (
          <KeyboardArrowDownIcon {...props} style={{ color: '#ffff' }} className="arrowDownIcon" /> // Change the color here
        )}

        label="Sub Plan"
        onChange={handleChange}
        sx={{
          color: "#fff",
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "#fff", // Border color
          },

          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "#fff", // Border color when focused
          },
        }}
      >
        {Subpackages?.map((subpackage, i) => (
          <MenuItem value={subpackage.name} key={i}>
            {subpackage.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

import React from 'react';
import { FaFacebook, FaTwitter, FaInstagram, FaLinkedin, FaYoutube } from 'react-icons/fa';

const SocialMediaIcons = ({ links }) => {
    console.log("link", links)
  const renderIcon = (name) => {
    switch (name.toLowerCase()) {
      case 'facebook':
        return <FaFacebook />;
      case 'twitter':
        return <FaTwitter />;
      case 'instagram':
        return <FaInstagram />;
      case 'linkedin':
        return <FaLinkedin />;
      case 'youtube':
        return <FaYoutube />;
      default:
        return null;
    }
  };

  return (
    <div style={{ display: 'flex', gap: '15px', alignItems: 'center' }}>
      {links?.map((social, index) => (
        <a 
          key={index} 
          href={social.link} 
          target="_blank" 
          rel="noopener noreferrer"
          style={{ color: 'white', textDecoration: 'none' }}
        >
          {renderIcon(social.socialMediaName)}
        </a>
      ))}
    </div>
  );
};

export default SocialMediaIcons;

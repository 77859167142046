import { useContext } from 'react';
import BookingCard from '../components/BookingCard';
import { Grid } from '@mui/material';
import './HomePage.css';
import { PlanContext } from '../contexts/PlanContext';
import Skeleton from '../layouts/YouTube';
import CardCarousel from '../layouts/CardCarousel';


function HomePage() {
  const { plans } = useContext(PlanContext);

  const sampleCards = [
    { image: 'https://via.placeholder.com/250x150?text=Card+1', title: 'Card 1', description: 'Description for Card 1' },
    { image: 'https://via.placeholder.com/250x150?text=Card+2', title: 'Card 2', description: 'Description for Card 2' },
    { image: 'https://via.placeholder.com/250x150?text=Card+3', title: 'Card 3', description: 'Description for Card 3' },
  
  ];

  console.log("PP >>", plans);

  if (!Array.isArray(plans)) {
    return <Skeleton/>; // Handle the case when plans are not yet loaded or not an array
  }

  return (
    <>
    <CardCarousel cards={sampleCards} />
    <Grid container className="HomePageContainer">
      
      {plans.length === 0 ? (
        <div>No plans available</div>
      ) : (
        plans.map((plan) => {
     if(plan.status === 'closed') return null;
     return(
          // <Grid item xs={12} md={4} key={plan._id}> 
          //   <BookingCard Plan={plan} />
          // </Grid>
          <>
          
          <Grid item xs={12} sm={6} md={3} key={plan.id}>
          <BookingCard Plan={plan} />
        </Grid>
          </>
         )
     })
      )}
    </Grid>
    </>
  );
}

export default HomePage;

import React, { useState } from "react";
import {
  TextField,
  Box,
  Grid,
  Typography,
  Button,
  Tooltip,
  IconButton,
  InputAdornment,
  useTheme,
  useMediaQuery
} from "@mui/material";
import * as yup from "yup";
import { useFormData } from "../../contexts/FormDataContext";
import InfoIcon from "@mui/icons-material/Info";

// Define validation schema with yup
const validationSchema = yup.object({
  name: yup.string("Enter your name").required("Name is required"),
  email: yup
    .string("Enter your email")
    .email("Enter a valid email")
    .required("Email is required"),
  confirmEmail: yup
    .string("Confirm your email")
    .oneOf([yup.ref("email"), null], "Emails must match")
    .required("Confirm Email is required"),
  phone: yup
    .string("Enter your mobile number")
    .matches(/^[0-9]{10}$/, "Enter a valid mobile number")
    .required("Mobile number is required"),
  address: yup.string("Enter your address").required("Address is required"),
});

const CheckoutForm = () => {
  const { formData, updateFormData } = useFormData();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [formValues, setFormValues] = useState(formData);

  const [errors, setErrors] = useState({});
  const [touched, setTouched] = useState({});
  const [showTooltip, setShowTooltip] = useState(false);

  


  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormValues((prev) => {
      const newFormValues = { ...prev, [name]: value };

      // Validate fields after the state has been updated
      if (name === "email" || name === "confirmEmail") {
        validateField("email", newFormValues.email);
        validateField("confirmEmail", newFormValues.confirmEmail);
      } else {
        validateField(name, value);
      }

      updateFormData(newFormValues);
      return newFormValues;
    });
  };

  const handleBlur = (e) => {
    const { name } = e.target;
    setTouched((prev) => ({ ...prev, [name]: true }));
    validateField(name, formValues[name]);
  };

  const handleTooltipClick = () => {
    if (isMobile) {
      setShowTooltip(!showTooltip);
    }
  };

  

  const validateField = (name, value) => {
    if (name === "email" || name === "confirmEmail") {
      // Validate both email and confirmEmail together
      try {
        validationSchema.validateSyncAt("email", { email: formValues.email });
        validationSchema.validateSyncAt("confirmEmail", {
          email: formValues.email,
          confirmEmail: formValues.confirmEmail,
        });
        setErrors((prev) => ({
          ...prev,
          email: undefined,
          confirmEmail: undefined,
        }));
      } catch (error) {
        setErrors((prev) => ({ ...prev, [error.path]: error.message }));
      }
    } else {
      // Validate the individual field
      try {
        validationSchema.validateSyncAt(name, { [name]: value });
        setErrors((prev) => ({ ...prev, [name]: undefined }));
      } catch (error) {
        setErrors((prev) => ({ ...prev, [name]: error.message }));
      }
    }
  };

  const validateForm = () => {
    try {
      validationSchema.validateSync(formValues, { abortEarly: false });
      setErrors({});
      return true;
    } catch (error) {
      const newErrors = error.inner.reduce((acc, curr) => {
        acc[curr.path] = curr.message;
        return acc;
      }, {});
      setErrors(newErrors);
      return false;
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const isValid = validateForm();
    if (isValid) {
      updateFormData(formValues);
    }
  };

  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 2,
        width: "300px",
        margin: "1rem auto",
      }}
    >
      <TextField
        fullWidth
        variant="filled"
        size="small"
        id="name"
        name="name"
        label="Name"
        value={formValues.name}
        onChange={handleChange}
        onBlur={handleBlur}
        error={touched.name && Boolean(errors.name)}
        helperText={touched.name && errors.name}
        sx={{ background: "#fff" }}
      />
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <TextField
            fullWidth
            variant="filled"
            size="small"
            id="email"
            name="email"
            label="Email"
            value={formValues.email}
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.email && Boolean(errors.email)}
            helperText={touched.email && errors.email}
            sx={{ background: "#fff" }}
          />
        </Grid>
        <Grid item xs={6} sx={{ position: "relative" }}>
          <TextField
            fullWidth
            variant="filled"
            size="small"
            id="confirmEmail"
            name="confirmEmail"
            label="Confirm Email"
            value={formValues.confirmEmail}
            onChange={handleChange}
            onPaste={(e) => e.preventDefault()}
            onBlur={handleBlur}
            error={touched.confirmEmail && Boolean(errors.confirmEmail)}
            helperText={touched.confirmEmail && errors.confirmEmail}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {isMobile ? (
                    <div>
                      <Tooltip
                        open={showTooltip}
                        title="E-Ticket will be send on this email address!"
                        placement="top"
                      >
                        <IconButton
                          aria-label="info"
                          onClick={handleTooltipClick}
                          sx={{position:"relative",
                            top:0,
                            right:"-20px",
                            
                          }}
                        >
                          <InfoIcon />
                        </IconButton>
                      </Tooltip>
                    </div>
                  ) : (
                    <Tooltip title="E-Ticket will be send on this email address!">
                      <IconButton aria-label="info"
                      sx={{position:"relative",
                        top:0,
                        right:"-20px"
                      }}>
                        <InfoIcon />
                      </IconButton>
                    </Tooltip>
                  )}
                </InputAdornment>
              ),
            }}
          />
        </Grid>
      </Grid>
      <TextField
        fullWidth
        variant="filled"
        size="small"
        id="phone"
        name="phone"
        label="Mobile Number"
        value={formValues.phone}
        onChange={handleChange}
        onBlur={handleBlur}
        error={touched.phone && Boolean(errors.phone)}
        helperText={touched.phone && errors.phone}
      />
      <TextField
        fullWidth
        variant="filled"
        size="small"
        id="address"
        name="address"
        label="Address"
        value={formValues.address}
        onChange={handleChange}
        onBlur={handleBlur}
        error={touched.address && Boolean(errors.address)}
        helperText={touched.address && errors.address}
      />
      {/* <Button type="submit" variant="contained" color="primary">
        Submit
      </Button> */}
      <Box>
        {/* <Typography variant="body1">
          Form Data: {JSON.stringify(formValues, null, 2)}
        </Typography> */}
      </Box>
    </Box>
  );
};

export default CheckoutForm;

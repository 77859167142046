// FetchAllPlans.js
import { useEffect, useContext, useState } from "react";
import axios from "axios";
import { PlanContext } from "../contexts/PlanContext";
import endpoints from "../Endpoints/endpoints";
import Loader from "../components/Loader";
const FetchAllPlans = () => {
  const { setPlans } = useContext(PlanContext);
  const [loading, setLoading] = useState(true);
  //const [planarray , setPlanArray] = useState([]);

  const sortplans = (planarray) => {
    const sortedarray = planarray.sort((a, b) => {
      if (a.status === "on" && b.status !== "on") {
        return -1;
      }
      if (a.status !== "on" && b.status === "on") {
        return 1;
      }
      return 0;
    });
    console.log("sorted array", sortedarray);
  };
  //console.log("plan array ", planarray);

  useEffect(() => {
    const fetchPlans = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`${endpoints.serverBaseURL}/api/plan`); // Replace with your API endpoint
        setPlans(response.data.plan);

        console.log("fetching plans: Success!");
      } catch (error) {
        console.error("Error fetching plans:", error);
      } finally {
        setLoading(false); // Set loading to false after fetching
      }
    };

    fetchPlans();
  }, []);

  return <>{loading && <Loader />}</>;
};

export default FetchAllPlans;

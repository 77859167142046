import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import ListItemText from "@mui/material/ListItemText";
import ListItemButton from "@mui/material/ListItemButton";
import List from "@mui/material/List";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import { useState } from "react";
import Slide from "@mui/material/Slide";
import Box from "@mui/material/Box";
import BasicChips from "../layouts/BasicChips";
import { Grid } from "@mui/material";
import {
  ChildFriendlyOutlined,
  MaleOutlined,
  Person2Outlined,
} from "@mui/icons-material";
import ItemCounter from "./ItemCounter";
import BasicDatePicker from "../layouts/BasicDatePicker";
import CheckoutForm from "./forms/CheckoutForm";
import CheckoutBill from "./forms/CheckoutBill";
import PayNowBtn from "./forms/PayNowBtn";
import PaymentPartner from "./forms/paymentPartner";
import CancellationPolicy from "./forms/CancellationPolicy";
import './Checkout.css';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function Checkout({ Plan, selectedSubPackage }) {
  const [open, setOpen] = React.useState(false);
  const [adultItem, setAdultItem] = React.useState(1);
  const [childItem, setChildItem] = React.useState(0);

  const handleQuantityChange = (itemCount, type, operation) => {
    if (type === "adult") {
      setAdultItem((prev) => Math.max(1, prev + operation));
    } else if (type === "child") {
      setChildItem((prev) => Math.max(0, prev + operation));
    }
    console.log("handle click :", itemCount);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const total =
    selectedSubPackage?.adult_price * adultItem +
    selectedSubPackage?.child_price * childItem;

  return (
    <React.Fragment>
      {Plan.status === "on" && (
        <Button
          size="small"
          variant="contained"
          className="buttons book-now-btn"
          onClick={handleClickOpen}
          disabled={
            Plan.status === "temporary closed" || Plan.status === "closed"
          }
        >
          {Plan.status === "temporary closed" || Plan.status === "closed"
            ? "Closed"
            : "Book Now"}
        </Button>
      )}

      {Plan.status === "temporary closed" && (
        // <Box className="bookingclosedBox">Booking Closed</Box>
        <Button
            size ='small'
            variant="contained"
            className="buttons book-now-btn closed-btn"
          >
           Closed
        </Button>
      )}
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar
          className="AppBar"
          sx={{ position: "relative", padding: "1rem 0" }}
        >
          <Toolbar>
            <Box sx={{ ml: 2, flex: 1 }}>
              <Typography variant="h6" component="div">
                {Plan.title}
              </Typography>
              <Typography
                variant="subtitle"
                component="div"
                sx={{ fontWeight: "light", letterSpacing: "0.1rem" }}
              >
                {selectedSubPackage?.name}
              </Typography>
            </Box>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>

        {/* ==================================================================================== */}

        <Box className="bg-gradient-light">
          {/*DESKTOP  */}
          <Grid container spacing={5}>
            <Grid item md={6} sx={{ background: "#fff" }}>
              <Grid container p={1} px={4} pt={3}>
                {/* Date ------------------------------------------ */}
                <Grid item xs={12}>
                  <BasicDatePicker />
                </Grid>
              </Grid>

              {/* Adult Price------------------------ */}
              <Grid container p={1}>
                <Grid
                  item
                  xs={4}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Box sx={{ display: "flex" }}>
                    <Person2Outlined />
                    <Typography variant="subtitle" px={1}>
                      Adult
                    </Typography>
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={4}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography variant="subtitle" px={1}>
                    ₹{selectedSubPackage?.adult_price*adultItem}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <ItemCounter
                    itemCount={adultItem}
                    type="adult"
                    handleQuantityChange={handleQuantityChange}
                  />
                </Grid>
              </Grid>

              {/* child Price------------------------ */}
              {selectedSubPackage.child_price !== -1 && (
                <Grid container p={1}>
                  <Grid
                    item
                    xs={4}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Box sx={{ display: "flex" }}>
                      <ChildFriendlyOutlined />
                      <Typography variant="subtitle" px={1}>
                        Child
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid
                    item
                    xs={4}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Typography variant="subtitle" px={1}>
                      ₹{selectedSubPackage?.child_price*childItem}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <ItemCounter
                      itemCount={childItem}
                      type="child"
                      handleQuantityChange={handleQuantityChange}
                    />
                  </Grid>
                </Grid>
              )}

              {/* Checkout form ----------------------------- */}
              <Grid
                container
                p={1}
                pt={3}
                mt={0}
                className=""
                sx={
                  {
                    // boxShadow:"0 0 28px 0px #eee", background:""
                  }
                }
              >
                <Grid
                  item
                  xs={12}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    variant="h6"
                    gutterBottom
                    textAlign="center"
                    sx={{
                      fontWeight: "bold",
                      opacity: 0.7,
                      marginBottom: "1rem",
                    }}
                  >
                    Personal Details
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <CheckoutForm />
                </Grid>
              </Grid>
            </Grid>

            <Grid item md={6}>
              {/* Checkout Bill ----------------------------- */}
              <Grid container p={2} pt={3} mb={0} className="" sx={{}}>
                <Grid
                  item
                  xs={12}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <CheckoutBill
                    selectedSubPackage={selectedSubPackage}
                    adultItem={adultItem}
                    childItem={childItem}
                    total={total}
                  />
                </Grid>
              </Grid>

              {/* Payment Partner ----------------------------- */}
              <PaymentPartner />

              <Grid container p={1} pt={3} mb={12} sx={{}}>
                <Grid
                  item
                  xs={12}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <CancellationPolicy />
                </Grid>
              </Grid>

              <Grid container p={1} pt={3} mb={12} className="" sx={{}}>
                <Grid
                  item
                  xs={12}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {/* <PayNowBtn/> */}
                  <PayNowBtn
                    totalAmount={total}
                    Plan={Plan}
                    adultItem={adultItem}
                    childItem={childItem}
                    selectedSubPackage={selectedSubPackage}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {/*DESKTOP  */}
        </Box>
        {/* ==================================================================================== */}
      </Dialog>
    </React.Fragment>
  );
}

import React, { useContext, useState } from "react";
import { Box, Grid, Typography, Button, CircularProgress } from "@mui/material";
import { usePayment } from "../../contexts/PaymentContext";
import { useFormData } from "../../contexts/FormDataContext";
import { load } from "@cashfreepayments/cashfree-js";
import { useNavigate } from "react-router-dom";
import Loader from "../../components/Loader";
import { toast, ToastContainer } from "react-toastify";  
import 'react-toastify/dist/ReactToastify.css';


const PayNowBtn = ({
  totalAmount = 0,
  Plan,
  adultItem,
  childItem,
  selectedSubPackage,
}) => {
  const GST_RATE = 0.18;
  const gst = totalAmount * GST_RATE;
  const grandTotal = totalAmount + gst;

  const {
    calculateTotal,
    createUser,
    paymentStart,
    verifyPayment,
    confirmBooking,
    cashfree,
    setUser
  } = usePayment();
  const { formData } = useFormData();

  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  // const [cashfree, setCashfree] = useState(null);

  const handlePayNow = async () => {
    console.log("Form Data:", formData);
    console.log("Cashfree SDK:", cashfree);

    if (!formData) {
      // alert("Please fill out the form before proceeding.");
      toast.error("Please fill out the form before proceeding."); 

      return;
    }

    setLoading(true);

    try {
      // Calculate total amount
      const { totalAmount, traceId } = await calculateTotal(
        Plan,
        adultItem,
        childItem,
        selectedSubPackage
      );

      // Create user
      const { userId,user } = await createUser(
        formData,
        Plan,
        adultItem,
        childItem,
        selectedSubPackage,
        traceId
      );
      setUser(user);
      console.log("Stored user",user);
      

      const paymentResponse = await paymentStart(
        totalAmount,
        userId,
        Plan,
        traceId,
        formData
      );
      const { paymentSessionId, orderId } = paymentResponse;
      console.log("Payment response,", paymentResponse);

      if (paymentSessionId && cashfree) {
        const checkoutOptions = {
          paymentSessionId: paymentSessionId,
          redirectTarget: "_modal",
        };

        // Initialize payment

        cashfree
          .checkout(checkoutOptions)
          .then(async () => {
            console.log("Payment initialized successfully.");
            setLoading(true);
            try {
              const paymentVerification = await verifyPayment(
                orderId,
                userId,
                traceId
              );

              if (paymentVerification.status === "success") {
                // Confirm booking
                const bookingResponse = await confirmBooking(
                  orderId,
                  Plan._id,
                  adultItem,
                  childItem,
                  selectedSubPackage.name,
                  userId,
                  formData.bookingDate,
                  selectedSubPackage,
                  traceId,
                  formData.franchiseCode
                );
                // {loading ? <Loader size={24} /> : "Booking is Confirmed,Please wait...."}
                setLoading(true);
                toast.success("Booking confirmed successfully!"); 
                // alert("Booking confirmed successfully.");
               
                navigate("/ticket", {
                  state: { booking: bookingResponse.booking,userData: user  },
                });
              } else {
                // alert("Payment verification failed.");
                toast.error("Payment verification failed."); 
              }
            } catch (verifyError) {
              console.error("Error during payment verification:", verifyError);
              // alert("Payment verification failed. Please try again.");
              toast.error("Payment verification failed. Please try again.");  
              setLoading(false);
              // navigate('/')
            }
          })
          .catch((error) => {
            console.error("Error during payment checkout:", error);
            // alert("Payment initiation failed. Please try again.");
            toast.error("Payment initiation failed. Please try again."); 
          });
      } else {
        throw new Error(
          "Failed to initiate payment session or Cashfree SDK not initialized."
        );
      }
    } catch (error) {
      console.error("Error during payment process:", error);
      // alert("Something went wrong. Please try again.");
      toast.error("Payment initiation failed. Please try again."); 
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
     {/* {loading && <Loader />}  */}
     <ToastContainer /> 
     {loading && <Loader />}
    <Box
      sx={{
        position: "fixed",
        zIndex: 100,
        bottom: 0,
        left: 0,
        width: "100%",
        backgroundColor: "#fff",
        borderTop: "1px solid #ccc",
        padding: "1rem",
        boxShadow: "0 -2px 10px rgba(0, 0, 0, 0.1)",
      }}
    >
      <Grid container spacing={0} alignItems="center">
        <Grid item xs={7}>
          <Typography variant="h6" fontWeight="Bold" component="div">
            ₹{grandTotal.toFixed(2)}
          </Typography>
          <Typography variant="body2" component="div" color="textSecondary">
            (Including all taxes )
          </Typography>
        </Grid>
        <Grid item xs={4} sx={{}}>
          <Button
            variant="contained"
            color="primary"
            size="large"
            sx={{ width: "100%" }}
            onClick={handlePayNow}
            className="gradient-primary-btn"
            disabled={loading}
          >
            {loading ? <CircularProgress size={24} /> : "Pay"}
          </Button>
        </Grid>
      </Grid>
    </Box>
    </>
  );
};

export default PayNowBtn;

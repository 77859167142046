import { Box, Button, Divider, Grid, Typography , Rating} from "@mui/material";
import React from "react";
import "./HeroSection.css";
import SubPlanSelect from "../../components/SubPlanSelect";
import BookingCardInfo from "../../components/BookingCardInfo";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import Checkout from "../../components/Checkout";
import "../../components/BookingCard.css";
import BasicChips from "../../layouts/BasicChips";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import { redirect } from "react-router-dom";
import {  Person2Outlined,  ChildFriendlyOutlined } from '@mui/icons-material';

function HeroSection({ plan, selectedSubPackage, setSelectedSubPackage }) {
  const handleBook = () => {
    const url = 'https://www.google.com/search?q=bhartiresort&rlz=1C1RXQR_enIN1094IN1094&oq=bhartiresort&gs_lcrp=EgZjaHJvbWUyBggAEEUYOTIJCAEQABgKGIAEMhUIAhAuGAoYrwEYxwEYgAQYmAUYmgUyGAgDEC4YChivARjHARiABBiOBRiYBRiZBTIVCAQQLhgKGK8BGMcBGIAEGJgFGJkFMgYIBRBFGDwyBggGEEUYPDIGCAcQRRg80gEINDE1NWowajeoAgiwAgE&sourceid=chrome&ie=UTF-8#lrd=0x3bc2e1015d5859b9:0x3045ab229e281d77,1,,,,';
    window.location.href = url;
  };


  return (
    <>
      <Grid container className="KnowMoreContainer hero-bg-gradient-light">
        <Grid item xs={12} md={4}>
          <img
            src={plan.image_list[0]}
            alt="plan image"
            className="PlanImgKnowMore"
          />

          <Grid container className="RatingContainer">
            <Grid item xs={8}>
            <Typography variant="body1" component="span" sx={{ marginRight: '8px', }}>
      4.6/5
             </Typography>
              <Rating
                className="rating-stars"
                name="read-only"
                value={4.6}
                precision={0.1}
                readOnly
                
                sx={{verticalAlign: 'middle'}}
              />
            </Grid>
            <Grid item xs={4}>
              <Button onClick={handleBook} variant="text">
                Rate Now
              </Button>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} md={8} p={5} className="planDetailsSection">
          <Typography variant="h5" fontWeight="bold" className="plantitle">
            {plan.title}
          </Typography>
          <Divider
            sx={{
              borderBottomWidth: 1,
              borderColor: "black",
              marginTop: "0.5rem",
            }}
          />
          <Grid container mb={5} className="plantimesection">
            <Grid item xs={12} marginBottom={2}>
              <Grid container width="55%" className="timetext">
                <Grid
                  item
                  xs={1}
                  md={1}
                  sx={{ fontWeight: "bold", opacity: "0.7", pl: "0.3rem", }}
                >
                  <AccessTimeIcon fontSize="small" />
                </Grid>
                <Grid item xs={11} md={11} mt={0.7}>
                  <Typography
                    variant="body2"
                    sx={{ fontWeight: "bold", opacity: "0.7" , ml:"0.2rem"}}
                  >
                    {plan.timing.fromtime} {plan.timing.fromperiod} -{" "}
                    {plan.timing.totime} {plan.timing.toperiod}
                  </Typography>
                </Grid>
                {/* Adult and child price */}
                <Grid container xs={11} md={11} sx={{ mt: 3 }}>
                  <Grid item xs={6} md={6}>
                    <BookingCardInfo
                      icon={<Person2Outlined fontSize="small" />}
                      info={`₹${selectedSubPackage.adult_price} + GST(12+ yrs)`}
                    />
                  </Grid>
                  <Grid item xs={6} md={6}>
                    {selectedSubPackage.child_price === -1 ? null : (
                      <BookingCardInfo
                        icon={<ChildFriendlyOutlined fontSize="small" />}
                        info={`₹${selectedSubPackage.child_price} + GST(5-11 yrs)`}
                      />
                    )}
                  </Grid>
                </Grid>

                <Grid item xs={11} md={11} mt={1}>
                  <Box className="subplanselect">
                    <SubPlanSelect
                      Subpackages={plan?.subpackages}
                      selectedSubPackage={selectedSubPackage}
                      setSelectedSubPackage={setSelectedSubPackage}
                    />
                  </Box>
                </Grid>
              </Grid>
              {/* <Divider sx={{ borderBottomWidth: 1, borderColor: 'black', marginTop:'1rem'}}  /> */}
            </Grid>

            {selectedSubPackage.facilities &&
              selectedSubPackage?.facilities.map((facilitie, i) => (
                <Grid item p={0.5}>
                  <Stack direction="row" spacing={1}>
                    <Chip label={facilitie} className="facilitiechips" />
                  </Stack>
                </Grid>
              ))}
          </Grid>

          <Box className="booknowbtn">
            <Checkout Plan={plan} selectedSubPackage={selectedSubPackage} />
          </Box>
        </Grid>
      </Grid>
    </>
  );
}

export default HeroSection;

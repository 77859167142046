// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DescriptionSection_gridAdultActivities__Y45Fz{
    margin-top: 0.7rem !important;
    background-color: #fff;
    padding: 1rem;
}

.DescriptionSection_descriptioncontainer__HAojK{
    padding: 1rem;
    background-color: #eeee;
}

.DescriptionSection_descriptionContainerItem__gEQMc{
    background-color: #fff;
    margin-top: -1rem !important;
}

.DescriptionSection_cardHeading__q-hvn{
    font-weight: bold !important;
    
}

.DescriptionSection_companyCardSection__BX52S{
    padding-left: 1rem;
}

@media (max-width :899px) {
    .DescriptionSection_companyCardSection__BX52S{
        padding-left: 0rem;
        padding-top: 1rem;
    }

    .DescriptionSection_descriptioncontainer__HAojK{
        background: rgba(0, 119, 255, 0.037) !important;
        background: linear-gradient(360deg, rgba(0, 119, 255, 0.011) 0%, rgba(62, 149, 255, 0.128) 100%) !important;
    }
}
`, "",{"version":3,"sources":["webpack://./src/pages/KnowMoreComponents/DescriptionSection.module.css"],"names":[],"mappings":"AAAA;IACI,6BAA6B;IAC7B,sBAAsB;IACtB,aAAa;AACjB;;AAEA;IACI,aAAa;IACb,uBAAuB;AAC3B;;AAEA;IACI,sBAAsB;IACtB,4BAA4B;AAChC;;AAEA;IACI,4BAA4B;;AAEhC;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI;QACI,kBAAkB;QAClB,iBAAiB;IACrB;;IAEA;QACI,+CAA+C;QAC/C,2GAA2G;IAC/G;AACJ","sourcesContent":[".gridAdultActivities{\n    margin-top: 0.7rem !important;\n    background-color: #fff;\n    padding: 1rem;\n}\n\n.descriptioncontainer{\n    padding: 1rem;\n    background-color: #eeee;\n}\n\n.descriptionContainerItem{\n    background-color: #fff;\n    margin-top: -1rem !important;\n}\n\n.cardHeading{\n    font-weight: bold !important;\n    \n}\n\n.companyCardSection{\n    padding-left: 1rem;\n}\n\n@media (max-width :899px) {\n    .companyCardSection{\n        padding-left: 0rem;\n        padding-top: 1rem;\n    }\n\n    .descriptioncontainer{\n        background: rgba(0, 119, 255, 0.037) !important;\n        background: linear-gradient(360deg, rgba(0, 119, 255, 0.011) 0%, rgba(62, 149, 255, 0.128) 100%) !important;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"gridAdultActivities": `DescriptionSection_gridAdultActivities__Y45Fz`,
	"descriptioncontainer": `DescriptionSection_descriptioncontainer__HAojK`,
	"descriptionContainerItem": `DescriptionSection_descriptionContainerItem__gEQMc`,
	"cardHeading": `DescriptionSection_cardHeading__q-hvn`,
	"companyCardSection": `DescriptionSection_companyCardSection__BX52S`
};
export default ___CSS_LOADER_EXPORT___;

import React from 'react';
import { useContext } from 'react';
import { Tabs, TabsList as BaseTabsList, Tab as BaseTab, tabClasses } from '@mui/base';
import { Link, useLocation } from 'react-router-dom';
import FilterListIcon from '@mui/icons-material/FilterList';
import { PlanContext } from '../contexts/PlanContext';
import { Box } from '@mui/material';

import { MdFilterAlt } from "react-icons/md";

import './TabBar.css'; // Import the CSS file


// Custom Link component to be used with Tabs
const RouterLink = React.forwardRef(function RouterLink(props, ref) {
  return <Link {...props} ref={ref} />;
});

export default function TabBar() {
  const { plans } = useContext(PlanContext);
  const location = useLocation();

  return (
    <Tabs value={location.pathname}>
      <div className="TabsContainer">
        <BaseTabsList className="TabsList">
           <Box className="filterIconBox">
           <MdFilterAlt  size="2.1rem" color='#ffff'/>
           </Box>
          <BaseTab
            value="/"
            to="/"
            className={`Tab ${location.pathname === '/' ? 'selected' : ''} allPlanTab`}
            slots={{ root: RouterLink }}
          >
            All Plans
          </BaseTab>
          {plans.map((plan) => {
            if (plan.status === 'closed') return null;
            return (
              <BaseTab
                key={plan._id}
                value={`/knowmore/${plan._id}`}
                to={`/knowmore/${plan._id}`}
                className={`Tab ${location.pathname === `/knowmore/${plan._id}` ? 'selected' : ''}`}
                slots={{ root: RouterLink }}
              >
                {plan.title}
              </BaseTab>
            );
          })}
        </BaseTabsList>
      </div>
    </Tabs>
  );
}

import * as React from 'react';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useFormData } from '../contexts/FormDataContext';
import dayjs from 'dayjs';
import { date } from 'yup';
export default function BasicDatePicker() {
  const { updateFormData } = useFormData(); 
  const [selectedDate, setSelectedDate] = React.useState(dayjs());

  React.useEffect(()=>{
     setSelectedDate(dayjs());
     updateFormData({bookingDate: dayjs()})
  },[])

  const handleDateChange = (date) => {
    setSelectedDate(date);
    updateFormData({ bookingDate: date ? date.toDate() : null });
  };

  const currentDate = dayjs();

  
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} w>
      <DemoContainer components={['DatePicker']}>
        <DatePicker label="Select Date" 
        value={selectedDate}
        onChange={handleDateChange}
        minDate={currentDate}
        format="DD-MM-YYYY"
        sx={{width:"100%"}} />
      </DemoContainer>
    </LocalizationProvider>
  );
}

import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import "./Loader.css";

const Loader = () => {
  return (
    <div className="loader-container">
      <div className="loader">
        <CircularProgress size={50} />
        <span>Please wait!...</span>
      </div>
    </div>
  );
};

export default Loader;

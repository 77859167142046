import React,{useContext} from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import './NavBar.css'; // Import the Sass file
import { AdminProfileContext } from '../contexts/AdminContext';
import { useNavigate } from 'react-router-dom';
import SocialMediaIcons from './NavBarComponents/SocialMediaIcons';
export default function NavBar({ BusinessName = "Business Name", Logo }) {
  const { adminProfile } = useContext(AdminProfileContext);
  const navigate = useNavigate();
  return (
    <Box sx={{ flexGrow: 1}}>
      <AppBar position='static' className='AppBar appbarheight'>
        <Toolbar>
          <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1 , "&:hover":{cursor:'pointer'}}} >
            <img 
              className='Logo' 
              src={adminProfile?.logo} 
              alt='logo'
              onClick={()=>{navigate('/')}}
            />
            <Typography 
              variant="body1" 
              component="div" 
              className="Typography"
              onClick={()=>{navigate('/')}}
            >
              {adminProfile?.name}
            </Typography>
               <Box sx={{ marginLeft: 'auto' }}>
            {/* <IconButton color="inherit">
              <SearchIcon />
            </IconButton> */}
          </Box>
          </Box>
          <SocialMediaIcons links={adminProfile?.socialMediaLinks} />
        </Toolbar>
        
      </AppBar>
    </Box>
  );
}

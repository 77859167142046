import React from "react";
import "./Footer.css";
import { useContext } from "react";
import { PlanContext } from "../contexts/PlanContext";
import { useNavigate } from "react-router-dom";
import {AdminProfileContext} from '../contexts/AdminContext'
import SocialMediaIcons from "./NavBarComponents/SocialMediaIcons";

const Footer = () => {
  const { plans } = useContext(PlanContext);
  const { adminProfile } = useContext(AdminProfileContext);
  const navigate = useNavigate();
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-section">
          <h3>Plans</h3>
          <ul>
            {
              plans.map((plan,i)=>{
                if(plan.status === 'closed') return null;
               return (
                <li>
                <div onClick={()=>{navigate(`/knowmore/${plan._id}`)}} className="planNameDiv">{plan.title}</div>
               </li>
              )})
            }
           
          </ul>
        </div>
        {/* <div className="footer-section">
          <h3>Events</h3>
          <ul>
            <li><a href="#!">Plays</a></li>
            <li><a href="#!">Sports</a></li>
            <li><a href="#!">Activities</a></li>
          </ul>
        </div> */}
        {/* <div className="footer-section">
          <h3>Experiences</h3>
          <ul>
            <li><a href="#!">IMAX</a></li>
            <li><a href="#!">4DX</a></li>
            <li><a href="#!">Luxury</a></li>
          </ul>
        </div> */}
        <div className="footer-section">
          <h3>Information</h3>
          <ul>
            <li>
              <a href="https://bhartiresort.com/about.html">About Us</a>
            </li>
            <li>
              <a href="https://bhartiresort.com/contact.html">Contact Us</a>
            </li>
            <li>
              <a href="https://bhartiresort.com/terms.html">Terms & Conditions</a>
            </li>
            <li>
              <a href="https://bhartiresort.com/privacy.html">Privacy Policy</a>
            </li>
          </ul>
        </div>
        <div className="footer-section">
          <h3>Follow Us</h3>
          <div className="social-icons">
            {/* <a href="#!">
              <i className="fab fa-facebook-f"></i>
            </a> */}
            {/* <a href="#!">
              <i className="fab fa-twitter"></i>
            </a> */}
            {/* <a href="#!">
              <i className="fab fa-youtube"></i>
            </a>
            <a href="#!">
              <i className="fab fa-instagram"></i>
            </a>
            <a href="#!">
              <i className="fab fa-linkedin-in"></i>
            </a> */}
             <SocialMediaIcons links={adminProfile?.socialMediaLinks}/>
          </div>
        </div>

        {/* <div className="footer-section">
      
          <div className="app-badges">
            <a href="#!"><img src="https://w7.pngwing.com/pngs/912/1019/png-transparent-app-store-google-play-apple-apple-text-logo-sign-thumbnail.png" alt="App Store" /></a>
            <a href="#!"><img src="https://media.licdn.com/dms/image/D5612AQEjgyzsUU2B_w/article-cover_image-shrink_600_2000/0/1672741349054?e=2147483647&v=beta&t=mTXdVMTS3IAUuxx-XAuQGMuy3jtczlQGbRtliggjevk" alt="Google Play" /></a>
          </div>
        </div> */}
        {/* google map */}
        <div className="footer-section footer-map">
          <h3>Our Location</h3>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d484360.82774785947!2d74.180953!3d18.480535!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2e1015d5859b9%3A0x3045ab229e281d77!2sBharti%20Resorts%20Water%20Adventure%20%26%20Amusement%20Park!5e0!3m2!1sen!2sin!4v1723029522825!5m2!1sen!2sin"
            width="100%"
            height="200"
            style={{ border: 0 }}
            allowFullScreen=""
            loading="lazy"
            title="Google Map"
          ></iframe>
        </div>
      </div>
      <div className="footer-bottom">
        <p>© 2024 Bharti Resort. All Rights Reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;

// src/components/WhatsAppButton.js
import React from "react";
import { IconButton, Tooltip } from "@mui/material";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { styled } from "@mui/material/styles";

const StyledButton = styled(IconButton)(({ theme }) => ({
  position: "fixed",
  bottom: theme.spacing(6),
  right: theme.spacing(1),
  backgroundColor: "#25D366", // WhatsApp green
  color: "white",
  "&:hover": {
    backgroundColor: "#1DA851",
  },
}));

const WhatsAppButton = () => {
  const handleClick = () => {
    window.open("https://api.whatsapp.com/send/?phone=7570909090&text&type=phone_number&app_absent=0", "_blank");
  };

  return (
    <Tooltip title="Chat with us on WhatsApp">
      <StyledButton onClick={handleClick}>
        <WhatsAppIcon />
      </StyledButton>
    </Tooltip>
  );
};

export default WhatsAppButton;

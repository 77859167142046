import React from 'react';
import { Box, Typography, IconButton } from '@mui/material';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';

const ItemCounter = ({ itemCount=2, type,handleQuantityChange }) => {
  return (
    <Box
      key={itemCount.id}
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      sx={{ margin: "0.5rem 0" }}
    >
 
      <Box display="flex" alignItems="center">
       
        <Box
          display="flex"
          alignItems="center"
          sx={{
            // border: "1px solid #ccc",
            borderRadius: 2,
            color: "#1BA672",
            marginLeft: "1rem",
            background:"#fff"
          }}
        >
          <IconButton onClick={() => handleQuantityChange(itemCount,type, -1)}>
            <RemoveIcon />
          </IconButton>
          <Typography sx={{ fontWeight: "700", color:"rgb(0,117,255)" }}>
            {itemCount}
          </Typography>
          <IconButton onClick={() => handleQuantityChange(itemCount,type, 1)}>
            <AddIcon sx={{ color: "rgb(0,117,255)" }} />
          </IconButton>
        </Box>
      </Box>
    </Box>
  );
};

export default ItemCounter;

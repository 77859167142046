// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AppBar{
    background: rgb(0,117,255);
    background: linear-gradient(90deg, rgba(0,117,255,1) 0%, rgba(62,150,255,1) 100%);
    
  
}

.appbarheight{
    height: 3.6rem;
    
}
.Logo{
    width: 3rem;
    border-radius: 20rem;
    margin:0.6rem 1rem 0.6rem 0 ;
}
.Typography {
    font-weight: bold;
    white-space: nowrap;
    margin-right: 16px;
  }
  
  /* .AppBar .SearchField {
    background-color:white;
    border-radius: 8px;
    max-width: 700px;
    width: 100%;
    margin-left: 2rem; 
} */


   
  

 

`, "",{"version":3,"sources":["webpack://./src/layouts/NavBar.css"],"names":[],"mappings":"AAAA;IACI,0BAA0B;IAC1B,iFAAiF;;;AAGrF;;AAEA;IACI,cAAc;;AAElB;AACA;IACI,WAAW;IACX,oBAAoB;IACpB,4BAA4B;AAChC;AACA;IACI,iBAAiB;IACjB,mBAAmB;IACnB,kBAAkB;EACpB;;EAEA;;;;;;GAMC","sourcesContent":[".AppBar{\n    background: rgb(0,117,255);\n    background: linear-gradient(90deg, rgba(0,117,255,1) 0%, rgba(62,150,255,1) 100%);\n    \n  \n}\n\n.appbarheight{\n    height: 3.6rem;\n    \n}\n.Logo{\n    width: 3rem;\n    border-radius: 20rem;\n    margin:0.6rem 1rem 0.6rem 0 ;\n}\n.Typography {\n    font-weight: bold;\n    white-space: nowrap;\n    margin-right: 16px;\n  }\n  \n  /* .AppBar .SearchField {\n    background-color:white;\n    border-radius: 8px;\n    max-width: 700px;\n    width: 100%;\n    margin-left: 2rem; \n} */\n\n\n   \n  \n\n \n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Styling for the primary container */
.bg-primary-gradient {
    background: linear-gradient(45deg, #3f51b5, #2196f3);
    padding: 16px;
    width: 100%;
    margin: 1rem auto;
    border: 1px solid #ccc;
    border-radius: 8px;
    color: #fff;
  }

    /* Styling for the grand total */
    .grand-total {
      font-weight: bold !important;
    }

  @media (max-width: 600px) {
    .bg-primary-gradient {
      margin-top: -30px !important; 
      margin-bottom: 1rem; 
    }
  }
  
  /* Styling for the flex containers */
  .checkout-bill-row {
    display: flex;
    justify-content: space-between;
    margin-top: 8px;
  }
  
  /* Styling for the divider */
  .checkout-divider {
    margin: 16px 0;
  }
 
  

  `, "",{"version":3,"sources":["webpack://./src/components/forms/CheckoutBill.css"],"names":[],"mappings":"AAAA,sCAAsC;AACtC;IACI,oDAAoD;IACpD,aAAa;IACb,WAAW;IACX,iBAAiB;IACjB,sBAAsB;IACtB,kBAAkB;IAClB,WAAW;EACb;;IAEE,gCAAgC;IAChC;MACE,4BAA4B;IAC9B;;EAEF;IACE;MACE,4BAA4B;MAC5B,mBAAmB;IACrB;EACF;;EAEA,oCAAoC;EACpC;IACE,aAAa;IACb,8BAA8B;IAC9B,eAAe;EACjB;;EAEA,4BAA4B;EAC5B;IACE,cAAc;EAChB","sourcesContent":["/* Styling for the primary container */\n.bg-primary-gradient {\n    background: linear-gradient(45deg, #3f51b5, #2196f3);\n    padding: 16px;\n    width: 100%;\n    margin: 1rem auto;\n    border: 1px solid #ccc;\n    border-radius: 8px;\n    color: #fff;\n  }\n\n    /* Styling for the grand total */\n    .grand-total {\n      font-weight: bold !important;\n    }\n\n  @media (max-width: 600px) {\n    .bg-primary-gradient {\n      margin-top: -30px !important; \n      margin-bottom: 1rem; \n    }\n  }\n  \n  /* Styling for the flex containers */\n  .checkout-bill-row {\n    display: flex;\n    justify-content: space-between;\n    margin-top: 8px;\n  }\n  \n  /* Styling for the divider */\n  .checkout-divider {\n    margin: 16px 0;\n  }\n \n  \n\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

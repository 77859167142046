// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bottomBookingBtn{
  position: fixed;
  bottom: 0;
  left: 0;
  display: flex ;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 2.8rem;
  /* background-color: #fff; */
}

.bottomBookingBtn button{
  width: 100%;
  height: 100%;
}

.sticky-navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #ffffff; /* Background color of the navbar */
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.1); /* Add shadow for depth */
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 20px;
  z-index: 1000; /* Ensure it stays on top of other elements */
}

.plan-name {
  font-size: 18px;
  font-weight: bold;
  
}

.navbar-book-now-btn.mobile{
    display: none;
}

.navbar-book-now-btn{
    width: 15%;
}



@media (min-width: 600px) {
    .bottomBookingBtn {
      display: none;
    }
  }

  @media (max-width:600px){
    .sticky-navbar{
      display: none;
    }
  }`, "",{"version":3,"sources":["webpack://./src/pages/KnowMorePage.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,SAAS;EACT,OAAO;EACP,cAAc;EACd,mBAAmB;EACnB,uBAAuB;EACvB,WAAW;EACX,cAAc;EACd,4BAA4B;AAC9B;;AAEA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,eAAe;EACf,MAAM;EACN,OAAO;EACP,WAAW;EACX,yBAAyB,EAAE,mCAAmC;EAC9D,wCAAwC,EAAE,yBAAyB;EACnE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,kBAAkB;EAClB,aAAa,EAAE,6CAA6C;AAC9D;;AAEA;EACE,eAAe;EACf,iBAAiB;;AAEnB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,UAAU;AACd;;;;AAIA;IACI;MACE,aAAa;IACf;EACF;;EAEA;IACE;MACE,aAAa;IACf;EACF","sourcesContent":[".bottomBookingBtn{\n  position: fixed;\n  bottom: 0;\n  left: 0;\n  display: flex ;\n  align-items: center;\n  justify-content: center;\n  width: 100%;\n  height: 2.8rem;\n  /* background-color: #fff; */\n}\n\n.bottomBookingBtn button{\n  width: 100%;\n  height: 100%;\n}\n\n.sticky-navbar {\n  position: fixed;\n  top: 0;\n  left: 0;\n  width: 100%;\n  background-color: #ffffff; /* Background color of the navbar */\n  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.1); /* Add shadow for depth */\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  padding: 20px 20px;\n  z-index: 1000; /* Ensure it stays on top of other elements */\n}\n\n.plan-name {\n  font-size: 18px;\n  font-weight: bold;\n  \n}\n\n.navbar-book-now-btn.mobile{\n    display: none;\n}\n\n.navbar-book-now-btn{\n    width: 15%;\n}\n\n\n\n@media (min-width: 600px) {\n    .bottomBookingBtn {\n      display: none;\n    }\n  }\n\n  @media (max-width:600px){\n    .sticky-navbar{\n      display: none;\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.booking-card-info-container {
    display: flex;
  }
  
  .booking-card-icon {
    opacity: 0.9;
    font-size: x-small;
    padding: 0 0.3rem;
  }
  
  .booking-card-info {
    opacity: 0.8;
    font-size: 0.7rem;
    padding-top: 0.1rem;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/BookingCardInfo.css"],"names":[],"mappings":"AAAA;IACI,aAAa;EACf;;EAEA;IACE,YAAY;IACZ,kBAAkB;IAClB,iBAAiB;EACnB;;EAEA;IACE,YAAY;IACZ,iBAAiB;IACjB,mBAAmB;EACrB","sourcesContent":[".booking-card-info-container {\n    display: flex;\n  }\n  \n  .booking-card-icon {\n    opacity: 0.9;\n    font-size: x-small;\n    padding: 0 0.3rem;\n  }\n  \n  .booking-card-info {\n    opacity: 0.8;\n    font-size: 0.7rem;\n    padding-top: 0.1rem;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import Box from '@mui/material/Box';
import BasicChips from '../layouts/BasicChips';
import Checkout from './Checkout';
import { Grid} from '@mui/material';
import { useNavigate } from 'react-router-dom';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function KnowMore({Plan, selectedSubPackage}) {
   const naviagte = useNavigate();
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    
    naviagte(`/knowmore/${Plan._id}`)
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <Button size="small" variant='outlined' className='buttons' onClick={handleClickOpen}>
       Know More
      </Button>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar className='AppBar'  sx={{ position: 'relative', padding:"1rem 0" }}>
          <Toolbar>
            <Box sx={{ ml: 2, flex: 1 }}>
            <Typography  variant="h6" component="div">
              {Plan.title}
            </Typography>
            <Typography  variant="subtitle" component="div" sx={{fontWeight:"light", letterSpacing:"0.1rem"}}>
              {selectedSubPackage.name}
        </Typography>
            </Box>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        
        <List>
        
          <ListItemButton>
            <ListItemText primary="Description" secondary={Plan.description} />
          </ListItemButton>
        
        </List>
        

        <Typography px={2} variant='body1'>Add-Ons</Typography> 
        <Grid container p={2}>    
            {selectedSubPackage.addOn.map((activity)=> <Grid item p={0.5}><BasicChips content={activity} /> </Grid>)} 
        </Grid>

        <Typography px={2} variant='body1'>Adult Activities</Typography> 
        <Grid container p={2}>    
            {selectedSubPackage.adult_activities.map((activity)=> <Grid item p={0.5}><BasicChips content={activity} /> </Grid>)} 
        </Grid>

        <Typography px={2} variant='body1'>Child Activities</Typography> 
        <Grid container p={2}>    
            {selectedSubPackage.child_activities.map((activity)=> <Grid item p={0.5}><BasicChips content={activity} /> </Grid>)} 
        </Grid>
        <Grid item xs={12} p={1}><Checkout Plan={Plan} selectedSubPackage={selectedSubPackage}/></Grid>
      </Dialog>
    </React.Fragment>
  );
}

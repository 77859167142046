import * as React from 'react';

import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import { useContext } from 'react';
import Avatar from '@mui/material/Avatar';

import Typography from '@mui/material/Typography';
import { red } from '@mui/material/colors';

import { LocationOnOutlined } from '@mui/icons-material';
import { AdminProfileContext } from '../contexts/AdminContext';


export default function CompanyInfoCard() {
  const { adminProfile } = useContext(AdminProfileContext);
  console.log("admin profiile" , adminProfile);
  return (
    <Card >
      <CardHeader
        avatar={
          <Avatar sx={{ bgcolor: red[500] }} src={adminProfile?.logo} aria-label="recipe"/>
        }
       
        title={adminProfile?.name}
         subheader="Adventure and Amusement Park"
      />
      <CardMedia
        component="iframe"
        height="194"
        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d484360.82774785947!2d74.180953!3d18.480535!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2e1015d5859b9%3A0x3045ab229e281d77!2sBharti%20Resorts%20Water%20Adventure%20%26%20Amusement%20Park!5e0!3m2!1sen!2sin!4v1723029522825!5m2!1sen!2sin"
        alt="google map"
      />
      <CardContent>
        <Typography variant="body2" color="text.secondary" sx={{display:'flex' , alignItems:"center"}}>
           <LocationOnOutlined sx={{mr:1}}/> 
            {adminProfile?.address}
        </Typography>
      </CardContent>
      
    </Card>
  );
}


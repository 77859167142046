import { Box, Button, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import "./HomePage.css";
import HeroSection from "./KnowMoreComponents/HeroSection";
import DescriptionSection from "./KnowMoreComponents/DescriptionSection";
import Checkout from "../components/Checkout";
import "./KnowMorePage.css";
import "../components/BookingCard.css"

function KnowMorePage({ plan }) {
  
  const [showNavBar, setShowNavbar] = useState(false);
  const [lastScrollY, setLastScrollY] = useState(0);
  const [selectedSubPackage, setSelectedSubPackage] = useState(
    plan?.subpackages[0]
  );
 

  useEffect(()=>{setSelectedSubPackage(plan?.subpackages[0])},[plan?.subpackages[0]]);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;

      if (currentScrollY > lastScrollY && currentScrollY > 400) {
        setShowNavbar(true); // User is scrolling down
      } else if(currentScrollY<=400) {
        setShowNavbar(false); // User is scrolling up or near the top
      }

      setLastScrollY(currentScrollY);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [lastScrollY]);

  

  
  return (
    <>   
       {  showNavBar && (
            <div className="sticky-navbar">
                 <div className="plan-name">
                    {plan.title} ({selectedSubPackage.name})
                 </div>
                 <div className="navbar-book-now-btn">
                    <Checkout Plan={plan} selectedSubPackage={selectedSubPackage}/>
                 </div>
            </div>
       )}
        
      <HeroSection
        plan={plan}
        selectedSubPackage={selectedSubPackage}
        setSelectedSubPackage={setSelectedSubPackage}
      />

      <Grid container sx={{ justifyContent: "center" }}>
        <DescriptionSection
          plan={plan}
          selectedSubPackage={selectedSubPackage}
        />
      </Grid>

      <Box className="bottomBookingBtn">
        <Checkout Plan={plan} selectedSubPackage={selectedSubPackage} />
      </Box>
    
    </>
  );
}

export default KnowMorePage;

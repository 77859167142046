import React from 'react';
import Slider from 'react-slick';
import './CardCarousel.css';
import img1 from '../assests/CarouselImg/img1.jpeg';
import img2 from '../assests/CarouselImg/img2.jpeg';
import img3 from '../assests/CarouselImg/img3.jpeg';

// const images = [
//   "https://assets-in.bmscdn.com/promotions/cms/creatives/1722935655122_monsoonpccweb.jpg",
//   "https://assets-in.bmscdn.com/promotions/cms/creatives/1717080055549_playcardweb.jpg",
//   "https://assets-in.bmscdn.com/promotions/cms/creatives/1722935655122_monsoonpccweb.jpg",
  
// ];
const images = [
   img1,img2,img3
];

const CardCarousel = () => {
  const settings = {
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 2000,
    centerMode: true,
    centerPadding: '20%',
    
   
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerPadding: '20%',
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerPadding: '0',
        }
      }
    ],
  
    // prevArrow: <div className="slick-prev" >&#10094;</div>, 
    // nextArrow: <div className="slick-next" >&#10095;</div> 
    prevArrow: <div className="slick-prev" />,
    nextArrow: <div className="slick-next" />
  };

  return (
    <div className="slider-container">
      <Slider {...settings}>
        {images.map((img, index) => (
          <div style={{margin:"0 0.5rem"}}>
               <div key={index} className="card">
            <img src={img} alt={`Slide ${index + 1}`} className="card-image" />
          </div>
          </div>
         
        ))}
      </Slider>
    </div>
  );
};

export default CardCarousel;

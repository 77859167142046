import React from "react";
import "./BookingCardInfo.css"; // Import the CSS file

function BookingCardInfo({ icon, info }) {
  return (
    <div className="booking-card-info-container">
      <b className="booking-card-icon">{icon}</b>
      <b className="booking-card-info">{info}</b>
    </div>
  );
}

export default BookingCardInfo;

// src/App.js
import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "@fortawesome/fontawesome-free/css/all.min.css";

import { PlanProvider } from "./contexts/PlanContext";
import Routes from "./routes";
import NavBar from "./layouts/NavBar";
import FetchAllPlans from "./api/fetchAllPlans";
import ToggleBar from "./layouts/ToggleBar";
import CardCarousel from "./layouts/CardCarousel";
import Footer from "./layouts/Footer";
import { AdminProfileProvider } from "./contexts/AdminContext";
import { FormDataProvider } from "./contexts/FormDataContext";
import { PaymentProvider } from "./contexts/PaymentContext";
import TabBar from "./layouts/TabBar";
import ScrolltoTop from "./layouts/ScrolltoTop";
import WhatsAppButton from "./layouts/WhatsAppButton";

const sampleCards = [
  {
    image: "https://via.placeholder.com/250x150?text=Card+1",
    title: "Card 1",
    description: "Description for Card 1",
  },
  {
    image: "https://via.placeholder.com/250x150?text=Card+2",
    title: "Card 2",
    description: "Description for Card 2",
  },
  {
    image: "https://via.placeholder.com/250x150?text=Card+3",
    title: "Card 3",
    description: "Description for Card 3",
  },
];

const App = () => {
  return (
    <>
      <FormDataProvider>
        <PaymentProvider>
          <AdminProfileProvider>
            <PlanProvider>
              <ScrolltoTop />
              <FetchAllPlans />
              <NavBar />
              {/* <ToggleBar /> */}
              <TabBar />
              {/* <CardCarousel cards={sampleCards} /> */}

             
          
                <Routes />
                <WhatsAppButton /> 
              
              
              <Footer />
            </PlanProvider>
          </AdminProfileProvider>
        </PaymentProvider>
      </FormDataProvider>
    </>
  );
};

export default App;

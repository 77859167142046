// src/routes.js
import React, { useContext } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import HomePage from "./pages/HomePage";
import CheckoutPage from "./pages/CheckoutPage";
import { PlanContext } from "./contexts/PlanContext";
import RoutedPlanPage from "./pages/RoutedPlanPage";
import Skeleton from "./layouts/YouTube";
import KnowMorePage from "./pages/KnowMorePage";
import Ticket from './components/Ticket';

const AppRoutes = () => {
  const { plans } = useContext(PlanContext);
  console.log("PP from routes>>", plans);

  if (plans === undefined) {
    return <Skeleton />; // Handle the case when plans are not yet loaded
  }

  const sortedPlans = [...plans].sort((a,b) => a.title.localeCompare(b.title));


  return (
   
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/checkout" element={<CheckoutPage />} />
        <Route path="/knowmore" element={<KnowMorePage />} />
        {sortedPlans.map((plan, index) => {
          //let title = clearTitle(plan.title);
          let id = plan._id;
          return (
            <>
              <Route
                path={id}
                key={index}
                element={<RoutedPlanPage plan={plan} />}
              />
              <Route
                path={`/knowmore/${id}`}
                key={index}
                element={<KnowMorePage plan={plan}/>}
              />
            </>
          );
        })}
        <Route path="/ticket" element={<Ticket />} />
      </Routes>
  
  );
};

export default AppRoutes;

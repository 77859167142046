// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
/* Loader.css */

 .loader-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1100;
  }
  
  .loader {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .loader span {
    font-size: 1.2rem;
    margin-left: 1rem;
  } `, "",{"version":3,"sources":["webpack://./src/components/Loader.css"],"names":[],"mappings":";AACA,eAAe;;CAEd;IACG,eAAe;IACf,MAAM;IACN,OAAO;IACP,WAAW;IACX,YAAY;IACZ,0CAA0C;IAC1C,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,aAAa;EACf;;EAEA;IACE,aAAa;IACb,mBAAmB;IACnB,uBAAuB;EACzB;;EAEA;IACE,iBAAiB;IACjB,iBAAiB;EACnB","sourcesContent":["\n/* Loader.css */\n\n .loader-container {\n    position: fixed;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n    background-color: rgba(255, 255, 255, 0.8);\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    z-index: 1100;\n  }\n  \n  .loader {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n  }\n  \n  .loader span {\n    font-size: 1.2rem;\n    margin-left: 1rem;\n  } "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import React from "react";
import styles from "./DescriptionSection.module.css";
import {  Grid, Typography } from "@mui/material";
import BasicChips from "../../layouts/BasicChips";

import CompanyInfoCard from "../../layouts/CompanyInfoCard";


export default function DescriptionSection({ plan, selectedSubPackage }) {
  
  return (
    <>
      <Grid container className={styles.descriptioncontainer}>
        <Grid item xs={12} md={8} maxWidth="md">
          <Grid item xs={12} className={styles.descriptionContainerItem} p={2}>
             
            <Typography variant="h6" className={styles.cardHeading} >
              About The Plan
            </Typography>
           
            <Typography variant="body1" mt={2} sx={{opacity:'0.7'}}>{plan.description}</Typography>
          
          </Grid>

          <Grid item xs={12} className={styles.gridAdultActivities}  >
            <Typography variant="h6" className={styles.cardHeading}>Add Ons</Typography>
            <Grid container p={2}>
              {selectedSubPackage.addOn.map((activity) => (
                <Grid item p={0.5}>
                  <BasicChips content={activity} />{" "}
                </Grid>
              ))}
            </Grid>
          </Grid>

          <Grid item xs={12} className={styles.gridAdultActivities}  >
           
            <Typography variant="h6" className={styles.cardHeading}>Adult Activities</Typography>
            
            <Grid container p={2}>
              {selectedSubPackage.adult_activities.map((activity) => (
                <Grid item p={0.5}>
                  <BasicChips content={activity} />{" "}
                </Grid>
              ))}
            </Grid>
          </Grid>
          <Grid item xs={12} className={styles.gridAdultActivities} >
              
            <Typography variant="h6" className={styles.cardHeading}>Child Activities</Typography>
            
            <Grid container p={2}>
              {selectedSubPackage.child_activities.map((activity) => (
                <Grid item p={0.5}>
                  <BasicChips content={activity} />{" "}
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={4} className={styles.companyCardSection}>
           <CompanyInfoCard/>
        </Grid>
      </Grid>
    </>
  );
}

import { createContext, useState, useEffect } from "react";
import axios from "axios";
import endpoints from "../Endpoints/endpoints";
export const AdminProfileContext = createContext();

export const AdminProfileProvider = ({ children }) => {
  const [adminProfile, setAdminProfile] = useState(null);

  useEffect(() => {
    const fetchAdminProfile = async () => {
      try {
        const response = await axios.get(
          `${endpoints.serverBaseURL}/api/admin/adminprofile`
        );
        setAdminProfile(response.data.adminprofile[0]);
        console.log("Fetching admin profile: Success!");
      } catch (error) {
        console.error("Error fetching admin profile:", error);
      }
    };

    fetchAdminProfile();
  }, []);

  return (
    <AdminProfileContext.Provider value={{ adminProfile }}>
      {children}
    </AdminProfileContext.Provider>
  );
};

import React from 'react';
import { Box, Grid, Typography } from '@mui/material';

const PaymentPartner = () => {
  const paymentPartners = [
    { name: 'MasterCard', logo: 'https://upload.wikimedia.org/wikipedia/commons/thumb/2/2a/Mastercard-logo.svg/1200px-Mastercard-logo.svg.png' },
    { name: 'GPay', logo: 'https://play-lh.googleusercontent.com/HArtbyi53u0jnqhnnxkQnMx9dHOERNcprZyKnInd2nrfM7Wd9ivMNTiz7IJP6-mSpwk' },
    { name: 'PhonePe', logo: 'https://play-lh.googleusercontent.com/6iyA2zVz5PyyMjK5SIxdUhrb7oh9cYVXJ93q6DZkmx07Er1o90PXYeo6mzL4VC2Gj9s' },
    { name: 'Paytm', logo: 'https://yt3.googleusercontent.com/5OqL88exM25Ew26whWDOgJwJ1I67kiCZr9TVxwT_2CVK-rxKH4qmL5peq72WgSMw6i_7znsI3Q=s900-c-k-c0x00ffffff-no-rj' },
  ];

  return (
    <Box sx={{ padding: 2, paddingY:"2rem" }} >
      <Typography variant="h6" gutterBottom textAlign="center" sx={{fontWeight:"bold", opacity:0.7, marginBottom:"1rem"}}>
        Payment Partners
      </Typography>
      <Grid container spacing={2} justifyContent="center" alignItems="center">
        {paymentPartners.map((partner) => (
          <Grid item key={partner.name} xs={3} sm={2} sx={{ display:"flex", justifyContent:"center", alignItems:"center"}} >
            <Box
          
              component="img"
              src={partner.logo}
              alt={partner.name}
              sx={{ width: '100%', height: 'auto', maxWidth: 50, borderRadius:"0.5rem" }}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default PaymentPartner;

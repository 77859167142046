import React, { useContext, useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography } from '@mui/material';
import { AdminProfileContext } from '../../contexts/AdminContext';

const CancellationPolicy = () => {
  const { adminProfile } = useContext(AdminProfileContext);
  const [open, setOpen] = useState(false);
  const policyPoints = adminProfile.cancellation_policy.split('.').filter(point => point.trim() !== '');
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Typography variant='body1' sx={{textAlign:"center"}}>By continuing, I acknowledge and accept the   <b className='CancellationPolicy' onClick={handleClickOpen}>
        Cancellation Policy
      </b> </Typography>  
    
      <Dialog open={open} onClose={handleClose} aria-labelledby="cancellation-policy-dialog">
        <DialogTitle id="cancellation-policy-dialog">Cancellation Policy</DialogTitle>
        <DialogContent>
          {/* <Typography variant="body1" paragraph>
            Our cancellation policy is as follows:
          </Typography>
          <Typography variant="body2" paragraph>
            - Cancellations made 7 days or more in advance of the event date will receive a 100% refund.
          </Typography>
          <Typography variant="body2" paragraph>
            - Cancellations made within 3 - 6 days will incur a 50% fee.
          </Typography>
          <Typography variant="body2" paragraph>
            - Cancellations made within 48 hours will incur a 100% fee.
          </Typography>
          <Typography variant="body2" paragraph>
            - No refunds will be given for no-shows.
          </Typography> */}
          <Typography variant='body2' paragraph>
          <ul>
        {policyPoints.map((point, index) => (
          <li key={index}>{point}</li>
           ))}
          </ul>
          </Typography>
          
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default CancellationPolicy;

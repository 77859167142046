// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* .Card{
    margin: 0.5rem;
} */
.Card {
    margin: 0.5rem;
    /* Applying elevation level 4 */
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2), 0px 6px 12px rgba(0, 0, 0, 0.3);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.Card:hover {
    transform: translateY(-5px); 
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.3), 0px 12px 24px rgba(0, 0, 0, 0.35);
}


.buttons{
    width: 100%;
    padding: 0.5rem  0 !important;
    border-radius: 0.3rem !important; 
    font-weight: 600 !important;
}
.book-now-btn{
    background: rgb(0,117,255) !important;
    background: linear-gradient(90deg, rgba(0,117,255,1) 0%, rgba(62,150,255,1) 100%) !important;
}
.gradient-primary-btn{
    background: rgb(0,117,255) !important;
    background: linear-gradient(90deg, rgba(0,117,255,1) 0%, rgba(62,150,255,1) 100%) !important;
}
.bg-primary-gradient{
    background: rgb(0,117,255) !important;
    background: linear-gradient(45deg, rgba(0,117,255,1) 0%, rgb(88, 163, 255) 100%) !important;
}
.bg-gradient-light{
    background: rgba(0, 119, 255, 0.037) !important;
    background: linear-gradient(60deg, rgba(0, 119, 255, 0.011) 0%, rgba(62, 149, 255, 0.128) 100%) !important;
}

.CancellationPolicy{
    color: rgb(0,117,255)  ;
}
`, "",{"version":3,"sources":["webpack://./src/components/BookingCard.css"],"names":[],"mappings":"AAAA;;GAEG;AACH;IACI,cAAc;IACd,+BAA+B;IAC/B,2EAA2E;IAC3E,qDAAqD;AACzD;;AAEA;IACI,2BAA2B;IAC3B,8EAA8E;AAClF;;;AAGA;IACI,WAAW;IACX,6BAA6B;IAC7B,gCAAgC;IAChC,2BAA2B;AAC/B;AACA;IACI,qCAAqC;IACrC,4FAA4F;AAChG;AACA;IACI,qCAAqC;IACrC,4FAA4F;AAChG;AACA;IACI,qCAAqC;IACrC,2FAA2F;AAC/F;AACA;IACI,+CAA+C;IAC/C,0GAA0G;AAC9G;;AAEA;IACI,uBAAuB;AAC3B","sourcesContent":["/* .Card{\n    margin: 0.5rem;\n} */\n.Card {\n    margin: 0.5rem;\n    /* Applying elevation level 4 */\n    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2), 0px 6px 12px rgba(0, 0, 0, 0.3);\n    transition: transform 0.3s ease, box-shadow 0.3s ease;\n}\n\n.Card:hover {\n    transform: translateY(-5px); \n    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.3), 0px 12px 24px rgba(0, 0, 0, 0.35);\n}\n\n\n.buttons{\n    width: 100%;\n    padding: 0.5rem  0 !important;\n    border-radius: 0.3rem !important; \n    font-weight: 600 !important;\n}\n.book-now-btn{\n    background: rgb(0,117,255) !important;\n    background: linear-gradient(90deg, rgba(0,117,255,1) 0%, rgba(62,150,255,1) 100%) !important;\n}\n.gradient-primary-btn{\n    background: rgb(0,117,255) !important;\n    background: linear-gradient(90deg, rgba(0,117,255,1) 0%, rgba(62,150,255,1) 100%) !important;\n}\n.bg-primary-gradient{\n    background: rgb(0,117,255) !important;\n    background: linear-gradient(45deg, rgba(0,117,255,1) 0%, rgb(88, 163, 255) 100%) !important;\n}\n.bg-gradient-light{\n    background: rgba(0, 119, 255, 0.037) !important;\n    background: linear-gradient(60deg, rgba(0, 119, 255, 0.011) 0%, rgba(62, 149, 255, 0.128) 100%) !important;\n}\n\n.CancellationPolicy{\n    color: rgb(0,117,255)  ;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

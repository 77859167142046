import { useState, useContext } from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import {Grid, Divider } from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import './BookingCard.css'
import BookingCardInfo from './BookingCardInfo';
import {  PoolOutlined, Restaurant, Person2Outlined,  ChildFriendlyOutlined, RadioButtonCheckedOutlined, Style } from '@mui/icons-material';
import SubPlanSelect from './SubPlanSelect';
import KnowMore from './KnowMore';
import { PlanContext } from '../contexts/PlanContext';
import Checkout from './Checkout';

function BookingCard({Plan}) {

    const { plans } = useContext(PlanContext);
  const [selectedSubPackage, setSelectedSubPackage] = useState(Plan.subpackages[0]);
console.warn("selectedSubPackage >>",selectedSubPackage.facilities);

    return (
        <Card className='Card' sx={{
            margin: '0.5rem',
            boxShadow: (theme) => theme.shadows[4], 
            transition: 'transform 0.3s ease, box-shadow 0.3s ease',
          }}>
            <CardMedia
                component="img"
                height="180"
                    image={Plan.image_list[0]}
                alt="Placeholder Image"
            />
            <CardContent>
                <Typography gutterBottom variant="h6" fontWeight="bold" component="div">
                    {Plan.title}
                </Typography>
                <Divider variant="full" />
                {/*  TODO:  Map Booking Info  */}
                <Grid container my={2}>
                    <Grid item xs={12}>
                        <BookingCardInfo  icon={<AccessTimeIcon  fontSize='small' />} info= {`${Plan.timing.fromtime}${Plan.timing.fromperiod}-${Plan.timing.totime}${Plan.timing.toperiod}`}/>
                    </Grid>
                         
                    {/* {Plan.title === "Day Picnic"? (
                        <>
                        {
                            selectedSubPackage?.facilities.map((facilitie,i)=> (
                                <Grid item xs={6} key={i}>
                                   {selectedSubPackage?.facilities.length > 0 ? <BookingCardInfo icon={<RadioButtonCheckedOutlined fontSize='small' />} info={facilitie}/>:null}
                                </Grid>
                            ))}
                        </>
                    ):
                    (null)} */}

                </Grid>

                <Divider variant="full" />


            {/* <SubPlanSelect Subpackages={Plan.subpackages} selectedSubPackage={selectedSubPackage} setSelectedSubPackage={setSelectedSubPackage}/> */}

              {/*  TODO:  Map Booking Info  */}

              <Grid container mt={0}>
                    <Grid item xs={6}>
                        <BookingCardInfo icon={<Person2Outlined fontSize='small' />} info={`₹${selectedSubPackage?.adult_price} + GST(12+ yrs)`}/>
                    </Grid>
                    <Grid item xs={6}>
                        {selectedSubPackage?.child_price === -1 ?(null):( <BookingCardInfo icon={<ChildFriendlyOutlined fontSize='small' />} info={`₹${selectedSubPackage?.child_price} + GST(5-11 yrs)`}/>
                        )}
                    </Grid>
                </Grid>

            </CardContent>
            <CardActions>
            <Grid container justifyContent="center" alignContent="center" textAlign="center">
                    <Grid item xs={6} p={1}><KnowMore Plan={Plan} selectedSubPackage={selectedSubPackage}/></Grid>
                    <Grid item xs={6} p={1}><Checkout Plan={Plan} selectedSubPackage={selectedSubPackage}/></Grid>
                   
                </Grid>
                
            </CardActions>
        </Card>
    );
}

export default BookingCard;
